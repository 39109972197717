/* eslint no-console:0 */

import "core-js/stable"
import "regenerator-runtime/runtime"
require.context("../images", true)

import "../index.scss"

import "utils/highcharts"
import 'utils/axios-next'
import 'utils/ahoy'
import 'controllers'
import 'components'
import "modules/rails"
import "modules/bs-native"

