import Rails from "@rails/ujs"
Rails.start()

import { Turbo } from "@hotwired/turbo-rails"

window.Turbo = Turbo

declare global {
  interface Window { Turbo: any }
}

if (window.location.pathname !== 'srcdoc')
  Turbo.start()

export { Turbo, Rails }
